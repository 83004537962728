<template>
  <v-form height="100%" v-model="valid" @submit.prevent="submitForm">
    <block v-if="loading"></block>
    <v-card>
      <v-card-title>
        <h3>Registrar Anticipo</h3>
      </v-card-title>
      <v-card-text class="w-full">
        <v-row class="mt-2">
          <v-col cols="12" md="6">
            <CustomAutoComplete
              key="customer_id"
              v-if="customers.length > 0"
              label="Seleccionar Cliente por ID"
              v-model="form.ID_Customer"
              :search="{ ...search, filteredFrom: 2 }"
              :items="customers"
              :item-text="'ID_Customer'"
              :item-value="'ID_Customer'"
              :filter-endpoint="$API.customers.filterCustomers"
              @select="onCustomerSelect"
              @updateItems="updateCustomers"
            />
          </v-col>
          <v-col cols="12" md="6">
            <CustomAutoComplete
              key="customer_name"
              v-if="customers.length > 0"
              label="Seleccionar Cliente por Nombre"
              v-model="form.ID_Customer"
              :search="{ ...search, filteredFrom: 2 }"
              :items="customers"
              :item-text="'itemText'"
              :item-value="'ID_Customer'"
              :filter-endpoint="$API.customers.filterCustomers"
              @select="onCustomerSelect"
              @updateItems="updateCustomers"
            />
          </v-col>
          <v-col cols="12" md="6">
            <date-picker
              label="Fecha de Pago"
              v-model="form.DatePayment"
              dense
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.Payment"
              label="Monto del Anticipo"
              type="number"
              outlined
              dense
              required
              :rules="[(v) => !!v || 'El monto es requerido']"
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-select
              v-model="form.TypePayment"
              :items="paymentTypes"
              item-text="Valores"
              item-value="Codigo"
              label="Tipo de Pago"
              @change="updateDescription"
              outlined
              dense
              required
            />
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="form.Reference"
              label="Referencia"
              outlined
              dense
              :disabled="!isReferenceEnabled"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="form.Comment"
              label="Comentario"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="closeModal" class="btndisable">Cancelar</v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="!valid" @click="submitForm" class="btnsave"
          >Registrar Anticipo</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapGetters } from "vuex";
import CustomAutoComplete from "@/components/CustomAutoComplete.vue";
import DatePicker from "@/components/DatePicker";

export default {
  name: "AdvancePaymentForm",
  components: {
    CustomAutoComplete,
    DatePicker,
  },
  props: ["win"],
  data() {
    return {
      loading: false,
      valid: false,
      form: {
        ID_Customer: "",
        CustomerName: "",
        DatePayment: new Date(),
        Payment: 0,
        Comment: "",
        IsActive: true,
        ID_Business: null,
        ID_Branch: null,
        TypePayment: "",
        DescriptionPayment: "",
        Reference: "",
      },
      branch: null,
      business: null,
      customers: [],
      search: {
        filter: "",
        filteredFrom: 2,
      },
    };
  },
  mounted() {
    try {
      this.business = JSON.parse(localStorage.getItem("user")).businessid;
      this.branch = JSON.parse(localStorage.getItem("user")).branch;
      this.search.business = this.business;
      this.search.branch = this.branch;
      this.form.ID_Business = this.business;
      this.form.ID_Branch = this.branch;
      this.getCustomers();
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    async getCustomers() {
      try {
        this.loading = true;
        const customersResponse = await this.$API.customers.filterCustomers(
          this.search
        );
        this.customers = this.mapCustomerFromNameToItemText(customersResponse);
      } catch (error) {
        console.error("Error fetching customers:", error);
      } finally {
        this.loading = false;
      }
    },
    onCustomerSelect(customer) {
      this.form.ID_Customer = customer;
    },
    updateCustomers(newItems) {
      this.customers = this.mapCustomerFromNameToItemText(newItems);
    },
    mapCustomerFromNameToItemText(customers) {
      return customers.map((customer) => {
        customer.itemText = customer.Is_a_business
          ? customer.BusinessName
          : customer.LegalName;
        return customer;
      });
    },
    async submitForm() {
      this.loading = true;
      try {
        await this.$API.customerAdvancePayment.createAdvancePayment(this.form);
        this.$emit("success", "Anticipo registrado exitosamente.");
        this.closeModal();
      } catch (error) {
        console.error("Error creating advance payment:", error);
        this.$emit("error", "Error al registrar el anticipo.");
      } finally {
        this.loading = false;
      }
    },
    closeModal() {
      this.form = {
        ID_Customer: "",
        CustomerName: "",
        DatePayment: null,
        Payment: 0,
        Comment: "",
        IsActive: true,
        ID_Business: null,
        ID_Branch: null,
        TypePayment: "01",
        DescriptionPayment: "",
        Reference: "",
      };
      this.$emit("close");
    },
    updateDescription() {
      const selectedPaymentType = this.paymentTypes.find(
        (type) => type.Codigo === this.form.TypePayment
      );
      this.form.DescriptionPayment = selectedPaymentType
        ? selectedPaymentType.Valores
        : "";
    },
  },
  computed: {
    ...mapGetters(["getCatalogMH"]),
    paymentTypes() {
      return this.getCatalogMH("FormaPago");
    },
    isReferenceEnabled() {
      return this.form.TypePayment !== "01";
    },
  },
};
</script>

<style scoped>
.btndisable {
  background-color: #41464c !important;
  color: white;
}

.btnsave {
  background-color: #00a178 !important;
  color: white;
}
</style>
