<template>
  <div>
    <DxDataGrid
      :dataSource="advancedPayments"
      :showBorders="true"
      ref="dataGrid"
      @exporting="onExporting"
      :scrolling="{ useNative: true }"
      :paging="{ enabled: false }"
      :column-auto-width="true"
      style="max-height: 600px"
    >
      <DxExport :enabled="true" />
      <DxColumn
        v-if="
          $store.getters['rolesUser/getCustomRole'](81) ||
          $store.getters['rolesUser/getCustomRole'](80)
        "
        cell-template="show-template"
        :width="200"
      />
      <DxColumn dataField="ID_AdvancePayment" caption="ID de Adelanto" />
      <DxColumn dataField="ID_Customer" caption="ID de Cliente" />
      <DxColumn dataField="CustomerName" caption="Nombre de Cliente" />
      <DxColumn
        dataField="DatePayment"
        caption="Fecha de Pago"
        dataType="date"
      />
      <DxColumn
        dataField="FormattedPayment"
        caption="Monto"
        dataType="number"
      />
      <DxColumn dataField="Comment" caption="Comentario" />
      <DxColumn dataField="IsActive" caption="Activo" dataType="boolean" />
      <DxColumn dataField="ID_Business" caption="ID de Negocio" />
      <DxColumn dataField="ID_Branch" caption="ID de Sucursal" />

      <template #show-template="{ data }">
        <div>
          <v-btn @click="handlePrint(data.data, 0)" class="mr-2" color="primary">Imprimir<br/>PDF</v-btn>
          <v-btn @click="handlePrint(data.data, 1)" color="primary">Imprimir<br/>Ticket</v-btn>
        </div>
      </template>
    </DxDataGrid>
    <div v-for="win in windowsList" :key="win.key">
      <component :is="win.component" v-bind="win.props" />
    </div>

    <div v-for="win in windowsListTicket" :key="win.key">
      <component :is="win.component" v-bind="win.props" />
    </div>
  </div>
</template>

<script>
import { DxDataGrid, DxColumn, DxExport } from "devextreme-vue/data-grid";
import PrintAdvancePayment from "@/components/ReportsAndPrintings/PrintAdvancePayment.vue";
import PrintAdvancePaymentTicket from "@/components/ReportsAndPrintings/PrintAdvancePaymentTicket.vue";
import moment from "moment";

export default {
  name: "AdvancedPaymentTable",
  components: {
    DxDataGrid,
    DxColumn,
    DxExport,
    PrintAdvancePayment,
  },
  props: {
    advancedPayments: {
      type: Array,
      required: true,
    },
    win: {
      type: Object,
    },
  },
  data() {
    return {
      customerId: null,
      showHistory: false,
      print: {
        show: false,
        header: {},
        data: [],
      },
      search: {
        branch: JSON.parse(localStorage.getItem("user")).branch,
        business: JSON.parse(localStorage.getItem("user")).businessid
      },
      windowsList: [],
      windowsListTicket: []
    };
  },
  methods: {
    onExporting(e) {
      console.log(e);
    },
    async handlePrint(advancePayment, type) {
      (this.print.header = {
        title: "Estado de Cuenta (Recibo de Anticipo)",
        subTitle: "ID de Anticipo: " + advancePayment.ID_AdvancePayment,
        businessName: JSON.parse(localStorage.getItem("user")).business,
        date: advancePayment.PaymentDate,
        customer: advancePayment.customer,
        currentDate: moment().format("DD/MM/YYYY"),
        ID_AdvancePayment: advancePayment.ID_AdvancePayment,
      }),
      (this.print.data.body = [advancePayment]);
      const TotalPayment = this.print.data.body.reduce(
        (a, b) => a + b.Payment,
        0
      );
      this.print.data.body.forEach((item, index) => {
        item.index = index + 1;
      });
      (this.print.branch = JSON.parse(localStorage.getItem("branch")));
      (this.print.user = JSON.parse(localStorage.getItem("user")));
      const infobusiness = (await this.$API.branches.findSpecificBranch(this.search))[0]
      this.print.branch.BusinessBillingNRC = infobusiness.BusinessBillingNRC;
      this.print.branch.BusinessBillingNIT = infobusiness.BusinessBillingNIT;
      this.print.branch.BusinessBillingName = infobusiness.BusinessBillingName;
      this.print.branch.DesActividad = infobusiness.DesActividad;

      this.print.show = true;
      this.print.footer = {
        TotalPayment: TotalPayment.toFixed(2),
        TotalPaymentShow:
          JSON.parse(localStorage.getItem("branch")).Currency +
          " " +
          TotalPayment.toFixed(2),
      };

      if (type === 0) {
        this.windowsList.push({
          component: PrintAdvancePayment,
          props: {
            dataProps: this.print,
          },
          key: new Date().getTime(),
        });
      }

      if (type === 1) {
        this.windowsListTicket.push({
          component: PrintAdvancePaymentTicket,
          props: {
            dataProps: this.print,
          },
          key: new Date().getTime(),
        });
      }
      
    },
  },
};
</script>
