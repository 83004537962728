<template>
  <v-tabs v-model="tab" dark background-color="primary" icons-and-text>
    <v-tabs-slider color="red"></v-tabs-slider>
    <v-tab href="#tab-1">
      Anticipos
      <v-icon>mdi-account-cash-outline</v-icon>
    </v-tab>
    <v-tabs-items v-model="tab" touchless>
      <v-tab-item id="tab-1" value="tab-1">
        <v-card flat style="padding: 16px" class="py-1 px-1">
          <v-card-text class="white" style="border-radius: 10px">
            <AdvancePaymentFilter :win="win" />
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import AdvancePaymentFilter from "./AdvancePaymentFilter.vue";

export default {
  name: "AdvancePaymentTab",
  components: { AdvancePaymentFilter },
  props: ["win"],
  data() {
    return {
      tab: null,
    };
  },
};
</script>
