<template>
  <v-row style="padding: 1rem 0" justify="space-between">
    <v-col cols="auto">
      <v-radio-group v-model="localSearch.typeData" @change="emitRadioChange" class="ma-3">
        <v-radio label="Ver reporte con datos de prueba" value="00"></v-radio>
        <v-radio label="Ver reporte con datos de producción" value="01"></v-radio>
      </v-radio-group>
    </v-col>
    <v-col cols="auto">
      <v-btn @click="emitNewAdvance" color="primary">Nuevo Anticipo</v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "AdvancePaymentControls",
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localSearch: {
        typeData: this.value,
      },
    };
  },
  methods: {
    emitRadioChange() {
      this.$emit("radioChange", this.localSearch.typeData);
    },
    emitNewAdvance() {
      this.$emit("newAdvance");
    },
  },
};
</script> 