<template>
    <window-portal :open="dataProps.show">
      <div
        style="
          font-family: arial !important;
          margin-top: 10px;
          margin-bottom: 10px;
        "
      >
        <div style="text-align: center; font-size: 30px">
          Vista previa de impresión de Anticipos Ticket
        </div>
        <br />
        <div style="text-align: center;">
          <button class="btnclean" style="font-size: 18px" @click="Print()">
            Imprimir
          </button>
          &nbsp;&nbsp;
          <button class="btnclean" style="font-size: 18px" @click="Download()">
            Descargar
          </button>
        </div>
        <div v-if="loading" style="text-align: center">Cargando...</div>
      </div>
      <div
        style="
          font-family: arial !important;
          margin-top: 10px;
          margin-bottom: 10px;
        "
      >
        <div v-if="mailLabel">{{ mailLabel }}</div>
      </div>
  
      <div
        class="book"
        style="
          margin: 0;
          padding: 0;
          background-color: rgb(105, 105, 105);
          font-family: arial !important;
          padding-top: 20px;
          padding-bottom: 20px;
        "
      >
        <div
          style="
            margin: 0 auto;
            width: 400px;
            display: flex;
            justify-content: center;
            font-size: 10pt;
          "
        >
          <div
            ref="dataprint"
            style="margin: 0 !important; padding: 0 !important; font-family: Arial !important;"
          >
            <div style="position: relative; width: 325px; background: white">
              <hr />
              <div class="subpage" style="padding-top: 20px; overflow: hidden">
                <div style="margin-left: 23px; margin-right: 33px">
                  
                  <table
                    style="
                      font-size: 8pt;
                      border-collapse: collapse;
                      width: 100%;
                      vertical-align: top;
                    "
                  >
                    <tr>
                      <td
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 100%;
                        "
                      >
                        <b>{{
                          dataProps.header.businessName
                        }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="
                          color: white;
                          text-align: center;
                          vertical-align: middle;
                          width: 100%;
                        "
                      >
                        <img
                          v-if="dataProps.branch?.BranchLogoPath != null && dataProps.branch?.BranchLogoPath != '' && dataProps.branch?.BranchLogoPath != 'null' && showImage"
                          style="max-width: 200px; height: 50px"
                          :src="dataProps.branch.BranchLogoPath"
                          crossorigin="anonymous"
                        />
                      </td>
                    </tr>
  
                    <tr>
                      <td
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 100%;
                        "
                      >
                        RAZÓN SOCIAL:
                        {{
                          dataProps.branch.BusinessBillingName !=
                            undefined ||
                          dataProps.branch.BusinessBillingName != null
                            ? dataProps.branch.BusinessBillingName.toUpperCase()
                            : ""
                        }}
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 100%;
                        "
                      >
                        ACTIVIDAD:
                        {{
                          dataProps.branch.DescActividad.toUpperCase()
                        }}
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 100%;
                        "
                      >
                        DIRECCIÓN:
                        {{
                          dataProps.branch.BranchLegalAddress != undefined ||
                          dataProps.branch.BranchLegalAddress != null
                            ? dataProps.branch.BranchLegalAddress.toUpperCase()
                            : ""
                        }}
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 100%;
                        "
                      >
                        TÉLEFONO:
                        {{
                          dataProps.branch.BranchPhone != undefined ||
                          dataProps.branch.BranchPhone != null
                            ? dataProps.branch.BranchPhone.toUpperCase()
                            : ""
                        }}
                        <br />
                        WHATSAPP:
                        {{
                          dataProps.branch.BranchMobile ||
                          dataProps.branch.BranchManagerMobile ||
                          dataProps.branch.BranchContactMobile
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 100%;
                        "
                      >
                        E-MAIL: {{ dataProps.branch.BranchEmail }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 100%;
                        "
                      >
                        NIT: {{ BillingNit }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="
                          text-align: center;
                          vertical-align: middle;
                          width: 100%;
                        "
                      >
                        NRC: {{ BillingNrc }}
                      </td>
                    </tr>
                  </table>
                  <hr />
                  <table
                    style="
                      font-size: 8pt;
                      border-collapse: collapse;
                      width: 100%;
                      vertical-align: top;
                    "
                  >
                    <tr>
                      <div style="display: flex; justify-content: space-between;">
                        <span>Fecha: {{ dataProps.header.currentDate }}</span>
                      </div>
                    </tr>
                    <br />
                    <tr>
                      <td
                        style="
                          text-align: left;
                          vertical-align: middle;
                          width: 100%;
                          font-size: 8pt;
                        "
                      >
                        {{ dataProps.header.title }}
                      </td>
                    </tr>
                  </table>
                  <!--table
                    style="
                      font-size: 8pt;
                      border-collapse: collapse;
                      width: 100%;
                      vertical-align: top;
                    "
                  >
                    <tr>
                      <td
                        style="
                          text-align: left;
                          vertical-align: middle;
                          width: 100%;
                        "
                      >
                        DATOS DEL RECEPTOR
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td
                        style="
                          text-align: left;
                          vertical-align: middle;
                          width: 100%;
                          font-size: 7pt;
                        "
                      >
                        RAZÓN SOCIAL:
                        <b>{{
                          dataProps.data.header.InvoiceName != undefined ||
                          dataProps.data.header.InvoiceName != null
                            ? dataProps.data.header.InvoiceName.toUpperCase()
                            : ""
                        }}</b>
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td
                        style="
                          text-align: left;
                          vertical-align: middle;
                          width: 100%;
                          font-size: 7pt;
                        "
                      >
                        DIRECCIÓN:
                        <b>{{
                          dataProps.data.header.InvoiceAddress != undefined ||
                          dataProps.data.header.InvoiceAddress != null
                            ? dataProps.data.header.InvoiceAddress.toUpperCase()
                            : ""
                        }}</b>
                      </td>
                    </tr>
                    <br />
                    <tr>
                      <td
                        style="
                          text-align: left;
                          vertical-align: middle;
                          width: 100%;
                          font-size: 7pt;
                        "
                      >
                        NIT: <b>{{ dataProps.data.header.InvoiceNIT }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="
                          text-align: left;
                          vertical-align: middle;
                          width: 100%;
                          font-size: 7pt;
                        "
                      >
                        DUI: <b>{{ dataProps.data.header.InvoiceDUI }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="
                          text-align: left;
                          vertical-align: middle;
                          width: 100%;
                          font-size: 7pt;
                        "
                      >
                        ENTIDAD: <b>{{ dataProps.data.header.InvoiceEntity }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="
                          text-align: left;
                          vertical-align: middle;
                          width: 100%;
                          font-size: 7pt;
                        "
                      >
                        MONEDA:
                        <b>{{
                          dataProps.data.header.InvoiceCurrency == "$"
                            ? "USD"
                            : ""
                        }}</b>
                      </td>
                    </tr>
                    <tr>
                      <td
                        style="
                          text-align: left;
                          vertical-align: middle;
                          width: 100%;
                          font-size: 7pt;
                        "
                      >
                        ESTADO DE FACTURA:
                        <b>{{
                          dataProps.data.header.InvoiceStatusDescription
                        }}</b>
                      </td>
                    </tr>
                  </table>
                    -->
                  <hr />
                  <table
                    style="
                      font-size: 7pt;
                      border-collapse: collapse;
                      width: 100%;
                      vertical-align: top;
                    "
                  >
                    
                    <tr v-for="(item, index) in dataProps.data.body" :key="index">
                      <td style="width: 100%">
                        <table
                          style="
                            width: 100%;
                            font-size: 8pt;
                            border-collapse: collapse;
                          "
                        >
                          <tr>
                            <td style="text-align: left; width: 50%">
                              <b>No. Item {{ index + 1 }}</b>
                            </td>
                            <td style="text-align: right; width: 50%"></td>
                          </tr>
                          <tr>
                            <td style="text-align: left; width: 50%">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;ID Anticipo:
                            </td>
                            <td style="text-align: right; width: 50%">
                              {{ item.ID_AdvancePayment }}
                            </td>
                          </tr>
                          <tr>
                            <td style="padding: 0" colspan="2">
                              <div
                                style="
                                  width: 25%;
                                  display: inline-block;
                                  text-align: left;
                                  vertical-align: top;
                                "
                              >
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Descripción:
                              </div>
                              <div
                                style="
                                  width: 75%;
                                  display: inline-block;
                                  text-align: right;
                                "
                              >
                                {{ item.Comment }}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td style="text-align: left; width: 50%">
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Valor Anticipo
                            </td>
                            <td style="text-align: right; width: 50%">
                              $ {{
                                item.Payment.toFixed(2)
                              }}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                  <hr />
                  <br /><br />
                  <table
                    style="
                      font-size: 7pt;
                      border-collapse: collapse;
                      width: 100%;
                      vertical-align: top;
                    "
                  >
                    <tr>
                      <td style="text-align: center">¡Gracias por su visita!</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </window-portal>
  </template>
  
  <script>
  import jsPDF from "jspdf";
  import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue";
  import { addFormatToNIT, addFormatNRC } from "../../helpers/misc";

  export default {
    name: "PrintAdvancePaymentTicket",
    props: ["dataProps"],
    components: { WindowPortal },
    data() {
      return {
        loading: false,
        numPages: 0,
        valuesRows: [],
        mailLabel: null,
        showImage: false,
      };
    },
    mounted() {
      this.calculatePagesOfDoc()
      this.showData()
    },
    computed: {
      BillingNrc() {
        return addFormatNRC(this.dataProps.branch.BusinessBillingNRC);
      },
      BillingNit() {
        return addFormatToNIT(this.dataProps.branch.BusinessBillingNIT);
      },
    },
    methods: {
        showData() {
          setTimeout(async () => {
            await this.getImages()
          }, 0)
        },
        getCustomerName() {
            return this.dataProps.header.customer.BusinessName
                ? this.dataProps.header.customer.BusinessName
                : this.dataProps.header.customer.LegalName;
        },
        calculatePagesOfDoc() {
            this.numPages = 1;
        },
        async Print() {
            this.loading = true;
  
            const pdfWidth = 8 * 28.35;
            var properties = {
                orientation: "p",
                unit: "pt",
                format: [pdfWidth, 400],
            }
  
            var doc = new jsPDF(properties);
  
            await doc.html(this.$refs.dataprint, {
            callback: function (doc) {
                doc.output();
            },
            x: 0,
            y: 0,
            margin: [0, 0, 0, 0],
            html2canvas: {
                scale: 0.698,
                useCORS: true,
                logging: false,
                allowTaint: true,
            },
            });
  
            //doc.deletePage(2)
  
            doc.autoPrint({ variant: "non-conform" });
            const blob = doc.output("bloburl");
            window.open(blob);
    
            this.loading = false;
        },
        async Download() {
            this.loading = true;
            const customerName = this.getCustomerName();
            const pdfWidth = 8 * 28.35;
            var properties = {
                orientation: "p",
                unit: "pt",
                format: [pdfWidth, 400],
            }
  
            var doc = new jsPDF(properties);
  
            await doc.html(this.$refs.dataprint, {
            callback: function (doc) {
                doc.output();
            },
            x: 0,
            y: 0,
            margin: [0, 0, 0, 0],
            html2canvas: {
                scale: 0.698,
                useCORS: true,
                logging: false,
                allowTaint: true,
            },
            });

            doc.save(
                "DOCUMENTO_ENTREGA_" +
                this.dataProps.header.ID_AdvancePayment +
                "_" +
                this.dataProps.header.currentDate.replaceAll("/", "-") +
                "_" +
                customerName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"', "") +
                ".pdf"
            );

            this.loading = false;
        },
        async getImages() {
            const logo = this.dataProps.branch.BranchLogoPath;
            const { imageBase64: logoImageBase64 } =
            await this.$API.utilities.getImageInBase64({ file: logo });
            this.dataProps.branch.BranchLogoPath = `${logoImageBase64}`;
            this.showImage = true
        },
    },
  };
  </script>
  
  <style scoped>
  .u-row .u-col-100 {
    width: 100% !important;
  }
  .btnclean {
    border-radius: 15px;
    border: 1px solid white;
    background-color: #f29d35 !important;
    color: white !important;
  }
  .btnclose {
    border-radius: 15px;
    border: 1px solid white;
    background-color: rgb(255, 38, 38) !important;
    color: white !important;
  }
  
  * {
    font-family: Arial, Helvetica, sans-serif !important;
    letter-spacing: 0.01px;
  }
  </style>
  