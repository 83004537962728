<template>
  <WorkspacePanel :win="win">
    <template v-slot:title>{{ $t("AdvancePayment") }}</template>
    <!-- TODO: Refactor, take care with prop drilling passing 'win' for many components that no use it. -->
    <AdvancePaymentTab :win="win" />
  </WorkspacePanel>
</template>

<script>
import WorkspacePanel from "../../../components/WorkspacePanel.vue";
import AdvancePaymentTab from "../tabs/AdvancePaymentTab.vue";

export default {
  name: "AdvancePayment",
  components: { WorkspacePanel, AdvancePaymentTab },
  props: ["win"],
};
</script>
