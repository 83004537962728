<template>
  <div>
    <AdvancePaymentControls
      :value="search.typeData"
      @radioChange="updateSearchType"
      @newAdvance="handleNewAdvance"
    />
    <GeneralFilter
      :filterEndpoint="fetchPayments"
      :search="search"
      @emptyFilter="getAllPayments"
      @filtered="applyFilter"
    >
      <template v-slot:default>
        <AdvancePaymentTable :advancedPayments="advancedPayments" :win="win" />
      </template>
    </GeneralFilter>
    <block v-if="loading" />
    <v-dialog
      v-model="isOpenAdvancePaymentDialog"
      scrollable
      :width="$vuetify.breakpoint.smAndDown ? '90%' : '90%'"
    >
      <AdvancePaymentForm
        @close="isOpenAdvancePaymentDialog = false"
        @success="showSuccessAlert"
      />
    </v-dialog>
    <Alert
      v-model="alert.show"
      @action="alert.action"
      :type="alert.type"
      :title="alert.title"
    >
      {{ alert.message }}
    </Alert>
  </div>
</template>

<script>
import GeneralFilter from "@/components/GeneralFilter.vue";
import Block from "@/components/Block.vue";
import AdvancePaymentTable from "./AdvancePaymentTable.vue";
import AdvancePaymentControls from "./AdvancePaymentControls.vue";
import AdvancePaymentForm from "./AdvancePaymentForm.vue";
import Alert from "../../../components/Alert.vue";

export default {
  name: "AdvancePaymentFilter",
  components: {
    GeneralFilter,
    AdvancePaymentTable,
    Block,
    AdvancePaymentControls,
    AdvancePaymentForm,
    Alert,
  },
  props: ["win"],
  data() {
    return {
      loading: false,
      advancedPayments: [],
      isOpenAdvancePaymentDialog: false,
      search: {
        ID_Customer: "",
        DatePayment: null,
        typeData: "01",
      },
      alert: {
        show: false,
        action: null,
        type: "",
        title: "",
        message: "",
      },
    };
  },
  mounted() {
    this.getAllPayments();
  },
  methods: {
    fetchPayments() {
      this.loading = true;
      return this.$API.customerAdvancePayment
        .findAllAdvancePayments(this.search)
        .then((response) => {
          this.advancedPayments = response.data;
          this.$emit("filtered", this.advancedPayments);
        })
        .catch((error) => {
          console.error("Error fetching payments:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showSuccessAlert(message) {
      this.alert.show = true;
      this.alert.type = "success";
      this.alert.title = "Éxito";
      this.alert.message = message;
      this.getAllPayments();
    },
    getAllPayments() {
      this.fetchPayments();
    },
    applyFilter() {
      this.fetchPayments();
    },
    updateSearchType(newType) {
      this.search.typeData = newType;
      this.fetchPayments();
    },
    handleNewAdvance() {
      this.isOpenAdvancePaymentDialog = true;
    },
  },
};
</script>
