<template>
  <window-portal :open="dataProps.show">
    <div
      style="
        font-family: arial !important;
        margin-top: 10px;
        margin-bottom: 10px;
      "
    >
      <center>
        <div>
          <p style="font-size: 30px">Vista previa de impresión de Anticipos</p>
        </div>
      </center>
      <br />
      <center>
        <button class="btnclean" style="font-size: 18px" @click="Print()">
          Imprimir
        </button>
        &nbsp;&nbsp;
        <button class="btnclean" style="font-size: 18px" @click="Download()">
          Descargar
        </button>
      </center>
      <center>
        <div v-if="loading"><p>Cargando...</p></div>
      </center>
    </div>

    <div
      style="
        font-family: arial !important;
        margin-top: 10px;
        margin-bottom: 10px;
      "
    >
      <center v-if="mailLabel">
        {{ mailLabel }}
      </center>
    </div>

    <div
      class="book"
      style="
        margin: 0;
        padding: 0;
        background-color: rgb(105, 105, 105);
        font-family: arial !important;
        padding-top: 20px;
        padding-bottom: 20px;
      "
    >
      <div style="margin: 0 auto; width: 1000px">
        <div
          ref="dataprint"
          style="margin: 0 !important; padding: 0 !important"
        >
          <div
            style="
              position: relative;
              width: 1000px;
              min-height: 1293px;
              background: white;
            "
          >
            <hr />
            <div
              class="subpage"
              style="height: 1250px; padding-top: 20px; overflow: hidden"
            >
              <div style="margin-left: 80px; margin-right: 50px">
                <div style="width: 100%;">
                  <table style="font-size: 10pt; border-collapse: collapse; width: 100%; vertical-align: top;">
                    <tr style="padding-right: 20px; padding-bottom: 5px;  color: white !important;">
                      <td>
                          <img v-if="dataProps.branch?.BranchLogoPath != null && dataProps.branch?.BranchLogoPath != '' && dataProps.branch?.BranchLogoPath != 'null' && showImage"
                           ref="logo" :src="dataProps.branch.BranchLogoPath" width="200px" height="auto" />
                      </td>
                    </tr>
                    <tr>
                        <td colspan="2">
                          <h3 style="font-size: 16px">
                            {{ dataProps.header.businessName }}
                          </h3>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="2"><b>{{ dataProps.branch.BusinessName }}</b></td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ dataProps.branch.DescActividad}}</td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ dataProps.branch.BranchLegalAddress}}</td>
                    </tr>
                    <tr>
                        <td colspan="2">Tel. {{ dataProps.branch.BranchPhone}}</td>
                    </tr>
                    <tr>
                        <td colspan="2">Whatsapp. {{ dataProps.branch.BranchMobile}}</td>
                    </tr>
                    <tr>
                        <td colspan="2">{{ dataProps.branch.BranchEmail}}</td>
                    </tr>
                    <tr>
                        <td colspan="2">NIT: {{ BillingNit }}</td>
                    </tr>
                    <tr>
                        <td colspan="2">
                            NRC: {{ BillingNrc }}
                        </td>
                    </tr>
                                                
                  </table>
                </div>

                <hr/>

                <div style="font-size: 12px">
                  <div style="display: flex; justify-content: space-between">
                    <h3 style="font-size: 12px">
                      Fecha: {{ dataProps.header.currentDate }}
                    </h3>
                  </div>
                  <h4>{{ dataProps.header.title }}</h4>
                  <p>{{ dataProps.header.subTitle }}</p>
                  <p>{{ dataProps.header.date }}</p>
                  <p>
                    {{ getCustomerName() }}
                  </p>
                </div>
                <br />
                <div style="width: 100%">
                  <table
                    style="
                      width: 100% !important;
                      font-size: 11px;
                      border-collapse: collapse;
                      border: 1px solid black;
                    "
                  >
                    <tr style="background: gray">
                      <td
                        colspan="5"
                        style="
                          text-align: center;
                          color: white;
                          background: gray;
                        "
                      >
                        CUERPO DEL DOCUMENTO
                      </td>
                    </tr>
                    <tr>
                      <td style="border: 1px solid; padding: 2px; width: 35px">
                        No.<br />Item
                      </td>
                      <td style="border: 1px solid; padding: 2px; width: 250px">
                        ID de Anticipo
                      </td>
                      <td style="border: 1px solid; padding: 2px; width: 450px">
                        Descripción
                      </td>
                      <td style="border: 1px solid; padding: 2px; width: 100px">
                        Valor Anticipo
                      </td>
                    </tr>
                    <tr
                      v-for="(item, index) in dataProps.data.body"
                      :key="index"
                    >
                      <td
                        style="
                          border-left: 1px solid;
                          padding: 2px;
                          border-right: 1px solid;
                        "
                      >
                        {{ index + 1 }}
                      </td>
                      <td
                        style="
                          border-left: 1px solid;
                          padding: 2px;
                          border-right: 1px solid;
                        "
                      >
                        {{ item.ID_AdvancePayment }}
                      </td>
                      <td
                        style="
                          border-left: 1px solid;
                          padding: 2px;
                          border-right: 1px solid;
                        "
                      >
                        {{ item.Comment }}
                      </td>
                      <td
                        style="
                          border-left: 1px solid;
                          padding: 2px;
                          border-right: 1px solid;
                        "
                      >
                        ${{ item.Payment.toFixed(2) }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
              <div
                style="position: absolute; bottom: 0; width: 100%; left: 80px"
              >
                <p style="font-size: 10px">Página 1 de 1</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </window-portal>
</template>

<script>
import jsPDF from "jspdf";
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue";
import { addFormatToNIT, addFormatNRC } from "../../helpers/misc";

export default {
  name: "PrintAdvancePayment",
  props: ["dataProps"],
  components: { WindowPortal },
  data() {
    return {
      loading: false,
      numPages: 0,
      valuesRows: [],
      mailLabel: null,
      showImage: false,
    };
  },
  mounted() {
    this.calculatePagesOfDoc();
    this.showData()
  },
  computed: {
    BillingNrc() {
      return addFormatNRC(this.dataProps.branch.BusinessBillingNRC);
    },
    BillingNit() {
      return addFormatToNIT(this.dataProps.branch.BusinessBillingNIT);
    },
  },
  methods: {
    showData() {
      setTimeout(async () => {
        await this.getImages()
      }, 0)
    },
    getCustomerName() {
      return this.dataProps.header.customer.BusinessName
        ? this.dataProps.header.customer.BusinessName
        : this.dataProps.header.customer.LegalName;
    },
    calculatePagesOfDoc() {
      this.numPages = 1;
    },
    async Print() {
      this.loading = true;
      var doc = new jsPDF("l", "pt", "letter");
      await doc.html(this.$refs.dataprint, {
        callback: function (doc) {
          doc.output();
        },
        x: 0,
        y: 0,
        margin: [0, 0, 0, 0],
        html2canvas: {
          scale: 0.437,
          useCORS: true,
          logging: false,
          allowTaint: true,
        },
      });
      doc.autoPrint({ variant: "non-conform" });
      const blob = doc.output("bloburl");
      window.open(blob);

      this.loading = false;
    },
    async Download() {
      this.loading = true;
      const customerName = this.getCustomerName();
      const doc = new jsPDF("l", "pt", "letter");
      await doc.html(this.$refs.dataprint, {
        callback: function (doc) {
          doc.output();
        },
        x: 0,
        y: 0,
        margin: [0, 0, 0, 0],
        html2canvas: {
          scale: 0.437,
          useCORS: true,
          logging: false,
          allowTaint: true,
        },
      });

      doc.save(
        "DOCUMENTO_ENTREGA_" +
          this.dataProps.header.ID_AdvancePayment +
          "_" +
          this.dataProps.header.currentDate.replaceAll("/", "-") +
          "_" +
          customerName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"', "") +
          ".pdf"
      );

      this.loading = false;
    },

    async getImages() {
      const logo = this.dataProps.branch.BranchLogoPath;
      const { imageBase64: logoImageBase64 } =
      await this.$API.utilities.getImageInBase64({ file: logo });
      this.dataProps.branch.BranchLogoPath = `${logoImageBase64}`;
      this.showImage = true
    },
  },
};
</script>

<style scoped>
.btnclean {
  border-radius: 15px;
  border: 1px solid white;
  background-color: #f29d35 !important;
  color: white !important;
}
</style>
